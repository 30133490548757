.navWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 12%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    background: rgb(14, 69, 98);
    background: rgb(14, 69, 98);
    background: linear-gradient(180deg, rgba(14, 69, 98, 1) 25%, rgba(14, 69, 98, 0.9917717086834734) 40%, rgba(14, 69, 98, 0.874124649859944) 55%, rgba(14, 69, 98, 0.678046218487395) 72%, rgba(14, 69, 98, 0) 100%);
}

.navImg {
    width: 5%;
    padding-top: 12px;

}
.navImg>div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
.navImg>div>img {
    width: 30%;
}

.ulNav {
    width: 80%;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    z-index: 4;
}

.ulNav>li:last-child p {
    background-color: var(--primaryBlue);
    color: var(--blueDark);
    padding: 8px;
    font-weight: 600;
    border-radius: 16px;
    margin-top: -8px;
}

.navWrapper>ul>li {
    padding: 18px 10px;
}

.burger {
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 14px;
    right: 32px;
    display: none;
    justify-content: space-around;
    flex-flow: column nowrap;
    cursor: pointer;
    align-items: flex-end;
}

.barBurger {
    width: 2rem;
    height: 0.25rem;
    background-color: var(--primaryGreen);
    border-radius: 4px;
    transform-origin: 1px;
    transition: all 0.5s linear;
}

.barBurger:nth-child(1) {
    width: 1.2rem;
    transform: rotate(0deg);
}

.barBurger:nth-child(2) {
    width: 2rem;
    transform: translateX(0);
    opacity: 1;
}

.barBurger:nth-child(3) {
    width: 1.7rem;
    transform: rotate(0deg);
}

.burgerUnactive {
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 14px;
    right: 32px;
    display: none;
    justify-content: space-around;
    flex-flow: column nowrap;
    opacity: .6;
    cursor: pointer;
}

.burgerUnactive>div:nth-child(1) {
    width: 2rem;
    transform: rotate(45deg);
}

.burgerUnactive>div:nth-child(2) {
    transform: translateX(100%);
    opacity: 0;
}

.burgerUnactive>div:nth-child(3) {
    width: 2rem;
    transform: rotate(-45deg);
}

@media(max-width: 1280px) {

    .ulNav {
        width: 85%;
    }
    .navImg {
        width: 6%;
    
    }
    

}

@media (max-width: 1024px) {
    .navImg {
        width: 8%;
        padding-top: 12px;
    }

    .burger {
        display: flex;
        z-index: 6;
        margin-top: 24px;
    }

    .burgerUnactive {
        display: flex;
    }

    .ulNav {
        flex-flow: column nowrap;
        background-color: var(--blueVariant);
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 300px;
        padding-top: 3em;
        transform: translateX(100%);
        transition: transform 0.5s ease-in-out;
    }

    .ulNav>li:last-child p {
        background-color: var(--primaryBlue);
        color: var(--blueDark);
        padding: 8px;
        font-weight: 600;
        border-radius: 16px;
        margin-top: -8px;
        width: 100px;
        text-align: center;
    }

    .ulNavActive {
        display: flex;
        flex-flow: column nowrap;
        list-style: none;
        background-color: var(--blueVariant);
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 350px;
        padding-top: 3em;
        padding-left: 2em;
        transform: translateX(0%);
        transition: transform 0.5s ease-in-out;
    }

    .ulNavActive>li:last-child p {
        width: 100px;
        background-color: var(--primaryBlue);
        color: var(--blueDark);
        padding: 8px;
        font-weight: 600;
        border-radius: 16px;
        margin-top: -8px;
        text-align: center;
    }

}

@media only screen and (max-width: 832px) and (orientation:portrait){

    .navImg {
        width: 18%;
    
    }
}