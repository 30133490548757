.loaderWrapper{
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
.wrapperAlien{
    width: 100%;
    padding: 0 40%;
}
.wrapperAlien>div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
.wrapperAlien>div>img {
    width: 30%;
}
.wrapperTexto{
    width: 100%;
    padding: 0 30%;
}