.wrapperTecnologia{
    display: flex;
    flex-flow: row  nowrap;
    width: 100%;
    padding-bottom: 10%;
}
.contentTecno{
    width: 55%;
    padding: 80px 80px 0px 80px;  
}
.contentTecno>p{
    width: 65%;
    color: var(--primaryBlue);
    margin-top: 40px;
}

.contentTecno>h4{
    width: 45%;
    margin-top: 40px;
}

.iconsTecno{
    width: 45%;
    padding: 80px;
    display: flex;
    flex-wrap: wrap;
    height: 90%;
}
.iconBox{
    width: 50%;
    height: 50%;
    padding: 8px;
    margin-top: 4%;
}
.icon{
    width: 50%;
}
.icon>img{
    width: 50%;
    height: 80px;
    margin-bottom: 24px;
}
.creatividad{
    color: var(--primaryBlue);
}
.disrupcion{
    color: var(--primaryGreen);
}
.innovacion{
    color: var(--primaryRed);
}

@media(max-width: 1024px) {
    .wrapperTecnologia{
        flex-flow: column  nowrap;
        width: 100%;
        height: auto;
    }
    .contentTecno{
        width: 90%;
        padding: 80px;  
    }
    .iconsTecno{
        width: 100%;
        padding: 80px;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        padding-top: 0;
    }
}
@media(max-width: 832px) and (orientation: portrait){
    
    .contentTecno{
        width: 100%;
        padding: 40px 12px 12px 12px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;  
    }

    .contentTecno>p{
        width: 100%;
        margin-top: 40px;
        padding: 0% 8%
    }

    .contentTecno>h1{
        width: 100%;
        padding: 0% 8%;
        text-align: center;
        font-size: calc(3.7rem + ((.1vw - 19.2px) * 1.75));
    }
    
    .contentTecno>h4{
        width: 60%;
        text-align: center;
    }

    .iconBox{
        width: 100%;
        height: auto;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .iconsTecno{
        width: 100%;
        padding: 12px;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        padding-top: 0;
        margin-top: 40px;
    }

    .icon{
        min-width: 40%;
        margin-right: 10px;
        text-align: center;
    }
    
    .icon img{
        width: 40%;
        margin-bottom: 0px;
    }
   
    
}

