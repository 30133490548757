.induWrap{
    width: 100%;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.titleCont{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}
.title{
    color: white;
    margin-bottom: 75px;
    line-height: 3.5rem;
}
.title span{
    font-size: calc(4rem + ((.5vw - 19.2px) * 1.75));
}
.text{
    width: 100%;
    margin: auto;
    text-align: center;
}

.text p{
    color: white;
    line-height: 20px;
}

.text span{
    color: var(--primaryGreen);
}

.iconCont{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 75px;
}

.icon{
    width: 15%;
    height: 250px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px;
    box-sizing: border-box;
}

.icon p{
    font-size: 24px;
}

.icon:nth-child(1) p{
    color: var(--primaryGreen);
}

.icon:nth-child(2) p{
    color: var(--primaryBlue);
}

.icon:nth-child(3) p{
    color: var(--primaryRed);
}

.induBtn{
    width: 25%;
    text-align: center;
    background-color: #55B4E6;
    border-radius: 20px;
    padding: 20px 0px;
    cursor: pointer;
    box-shadow: 1px 10px 37px -4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 10px 37px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 10px 37px -4px rgba(0,0,0,0.75);
    margin-top: 70px;
}

.induBtn p{
    font-size: 24px;
}

.induBtn:hover{
    background-color: #136997;
}

.induBtn p:hover{
    color: white;
}

@media only screen and (max-width: 1024px){
    .text{
        width: 70%;
    }
    .iconCont{
        flex-wrap: wrap;
    }
    .icon{
        width: 20%;
    }
    .icon svg{
        width:80%
    }
}

@media only screen and (max-width: 832px) and (orientation:portrait){
    .titleCont{
        width: 100%;
        padding-top: 40px;
    }
    .title{
        font-size: calc(4rem + ((.1vw - 19.2px) * 1.75));
        margin: auto;
        line-height: 2rem;
    }
    .title span{
        font-size: calc(3.6rem + ((.1vw - 19.2px) * 1.75));
    }
    .text{
        width: 90%;
        margin-top: 60px;
    }
    .text p{
        font-size: 18px;
    }
    .iconCont{
        margin-top: 45px;
        flex-direction: row;
    }
    .icon{
        width: 20%;
        height: 150px;
        padding: 20px 10px;
    }
    .icon p{
        font-size: 18px;
    }
    .induBtn{
        width: 45%;
        padding: 15px 0px;
        margin-top: 40px;
    }
}

@media only screen and (max-width: 768px){

    
}