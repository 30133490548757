.homeWrapper{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}
.loader{
    height: 100vh;
}
.backgroundFlair{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.backgroundFlair>img{
    transform: rotate(00deg);
    transform-origin: center;
    transform-style: preserve-3D;
    transition: transform .5s linear;
}
