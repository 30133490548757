.wrapperQuienes{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    padding-bottom: 10%;
}
.wrapperInfo{
    width: 55%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-start;
    order: 1;
}
.wrapperTitle{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 8%;
}
.wrapperText{
    width: 100%;
    padding: 0 8%;
    margin: 6.33% 0;
    display: flex;
    justify-content: flex-start;
}
.wrapperText h2{
    width: 100%;
    max-width: 600px;
    color: var(--primaryGreen);
}
.wrapperImg{
    width: 100%;
    max-height: 500px;
    overflow: hidden;
}
.wrapperImg>img{
    width: 100%;
    height: auto;
    object-fit: contain;
}
.wrapperCubes{
    width: 45%;
}
.wrapperCubes>div{
    background-color:var(--blueVariant);
    border-radius:16px;
    margin: 0 auto;
    width: 60%;
    padding: 10px;
}
.desktop{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.cube{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.desktop .cube{
    width: 50%;
    height: 150px;
}
.infoCube{
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.text{
    width: 100%;
    position: relative;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.icon ,.title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}
.icon>div{
    width: 100%;
    padding-top: 30%;
    mask-position: center !important;
    mask-repeat: no-repeat !important;
    mask-size: 30% !important;
    background-color: var(--primaryRed);

}
.title p{
    color: var(--primaryBlue);
    text-align: center;
}
.desktop .wrapperCubes{
    min-width: 450px;

}
.desktop .cube:nth-child(even){
    border-left: solid 1px var(--primaryBlue);
    border-bottom: solid 1px var(--primaryBlue);
}
.desktop .cube:nth-child(odd){
    border-bottom: solid 1px var(--primaryBlue);
}
.desktop .cube:nth-last-child(2),.desktop .cube:last-child{
    border-bottom: none;
}
.desktop .text{
    position: absolute;
    width: 0%;
    height: 101%;
    left: 100%;
    opacity: 0;
    transition: all .5s ease-in-out;
    overflow: hidden;
    text-align: left;
}
.desktop .cube:hover{
    background-color: var(--blueDark);
    z-index: 1;
}
.desktop .cube:hover .text{
    opacity: 1;
    width: 180%;
    background-color: var(--blueDark);
    border-top: solid 1px var(--primaryBlue);
    border-radius: 0 0 16px 0;

}
.desktop .cube:hover .icon>div{
    background-color: var(--greenDark);

}
.desktop .cube:first-child:hover .text,.desktop .cube:nth-child(2) .text{
    border: none;
    border-bottom: solid 1px var(--primaryBlue);
    border-radius: 0 16px 0 0;

}

.mobile :global(.slick-list){
    padding-bottom: 20%;
}

/*arrows slider*/
.mobile .icon_arrow{
   background: transparent url(../../assets/img/arrow.svg) no-repeat center !important;
   background-size: contain !important;
   top: 90%;
   z-index: 1;
   transition: all .5s ease-in-out;
}
.mobile :global(.slick-disabled){
    opacity: 0;
}
.mobile .arrow_next:active{
    transform: scale(1.5) rotate(180deg);
}
.mobile .arrow_prev:active{
   transform: scale(1.5) rotate(0deg);
}
.mobile .icon_arrow::before{
    content: '';
}
.mobile .arrow_next{
    right: 0;
    transform: scale(1) rotate(180deg);
}
.mobile .arrow_prev{
    left: 0;
   transform: scale(1) rotate(0deg);
}

@media(max-width: 832px) and (orientation: portrait){
    .wrapperTitle{
        padding-top: 40px;
    }
}


@media (max-width: 768px) {
    .wrapperQuienes{
        flex-wrap: wrap;
    }
    .wrapperInfo{
        width: 100%;
        order: 0;
    }
    .wrapperCubes{
        width: 100%;
    }
}