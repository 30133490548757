.wrapperAlidado{
    width: 100%;
    height: 100%;
    padding: 0 2.77%;
    padding-bottom: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-end;
    position: relative;
}
.wrapperVideo{
    position: absolute;
    width: 55% !important;
    padding-top: 45% !important;
    right: 0;
    top: -5%;
    background-color: var(--blueDark);
    z-index: -1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.wrapperVideo video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
}

.wrapperVideo .filter{
    background-color: black;
}
.wrapperTitle{
    width: 100%;
    max-width: 60%;
    padding: 0 8%;
}
.wrapperText{
    width: 100%;
    max-width: 60%;
    padding: 0 8%;
    margin-top: 6.33%;
}
.wrapperText h2{
    color: var(--primaryGreen);
}
.wrappertable{
    margin: 6.33% 0;
    background-color:var(--blueVariant);
    width: 100%;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
}
.wrappertable div{
    width: 33%;
    padding: 20px;
}
.wrappertable div:nth-child(2){
    border-left: solid 1px var(--primaryBlue);
    border-right: solid 1px var(--primaryBlue);
}

@media(max-width: 832px) and (orientation: portrait){

    .wrapperTitle{
        padding-top: 40px;
    }
}

@media (max-width: 768px) {
    .wrappertable{
        flex-wrap: wrap;
        align-content: flex-start;
    }
    .wrappertable div{
        width: 100%;
        padding: 20px;
    }
    .wrappertable div:nth-child(2){
        border: none;
        border-top: solid 1px var(--primaryBlue);
        border-bottom: solid 1px var(--primaryBlue);
    }
    .wrapperTitle{
        max-width: 100%;
    }
    .wrapperText{
        max-width: 100%;
    }
}
  