.wrapperContact {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-flow: column nowrap;
}

.wrapperContact>h1 {
    text-align: center;
    margin-bottom: 32px;
}

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
}

.formContainer {
    width: 50%;
    padding: 24px;
    display: flex;
    justify-content: center;
    height: 100%;
}

.contactData {
    width: 50%;
    border-right: 2px solid var(--primaryBlue);
}

.contactData {
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.contactData>p {
    text-align: right;
    width: 50%;
}

.socialIcons {

    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.socialIcons>div {
    margin-left: 24px;
}
.avisoPriv{
    width: 100%;
    height: 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding-left: 24px;
}
.avisoPriv>a{
    margin: 16px;
}

@media(max-width: 1024px) {
    .wrapperContact {

        height: auto;
    }

    .contact {
        flex-flow: column nowrap;
        height: auto;
    }

    .contactData {
        width: 80%;
        border-right: 0px solid var(--primaryBlue);
        align-items: center;
        height: 400px;
        order: 2;
    }

    .formContainer {
        width: 100%;
        padding: 4px;
        display: flex;
        justify-content: center;
        height: auto;
    }
}

@media only screen and (max-width: 1024px){
    .form {
        width: 90%;
    }
    .wrapperContact>h1{
        padding-top: 40px;
    }
    .contactData {
        width: 100%;
        height: 250px;
        padding-top: 100px;
    }
    .contactData>p {
        text-align: center;
    }
    .socialIcons {
        width: 20%;
        justify-content: space-evenly;
    }
    .socialIcons div {
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .socialIcons img{
        width: 70%;
    }
    .avisoPriv{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        position: relative;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    .avisoPriv>a{
        margin: unset;
    }
    .avisoPriv p{
        position: absolute;
        right: 50%;
        left: 50%;
    }
}

@media only screen and (max-width: 832px) and (orientation:portrait){
    
    .contactData>p {
        font-size: calc(1.125rem + ((2.5vw - 19.2px) * 0.375));
        width: 80%;
        margin: 10px 0px;
    }

    .socialIcons {
        width: 50%;
    }

    .avisoPriv{
        width: 80%;
    }
    
}