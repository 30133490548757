.wrapper{
    width: 100%;
    height: 75%;
    position: relative;
    padding: 5%;
    transition: all .5s linear;
}
.wrapper>div{
    border-radius: 8px;
    background-color: var(--blueDark);
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    box-shadow: -4px -4px 16px #14658F, 4px 4px 16px #082636;
    pointer-events: none;
}
:global(.slick-current) .wrapper{
    transform: scale(1.1);
}
:global(.slick-current) .wrapper>div{
    pointer-events: all;
}
/* :global(.more) :global(.slick-current) .wrapper{
    position: absolute;
    z-index: 3;
    opacity: 1;
    transform: scale(1);
} */
:global(.more) :global(.slick-slide):not(:global(.slick-current)) .wrapper>div::after{
    content: "";
    background-color: var(--blueDark);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; 
    opacity: .7;
    border-radius: 8px;
    z-index: 2;
}

.name {
    margin-top: 5%;
    width: 100%;
    z-index: 1;
}
.name>h2{
    color: var(--primaryBlue);
    text-align: center;
}
.position{
    width: 100%;
    margin-top: 10px;
    z-index: 1;
}
.position>h4{
    text-align: center;
    color: var(--primaryGreen);
    line-height: 1;
    font-weight: 100;
}
.render{
    width: 100%;
    position: absolute;
    bottom: 0;
}
.render>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.btnMore{
    position: absolute;
    bottom: 2%;
    cursor: pointer;
    display: none;
}
:global(.slick-current) .wrapper .btnMore{
    display: block;
}
:global(.more) :global(.slick-current) .btnMore{
    top: 2%;
    right: 2%;
    bottom: unset;
    transform: rotate(45deg);
    z-index: 10;
}

.wrapperMedallas{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
}

.titleMedalla{
    width: 100%;
    max-width: 250px;
    padding: 2% 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    border-radius: 8px;
    backdrop-filter: blur(42px);
    border: 2px solid;
    border-image-slice: 10;
    border-image-source: radial-gradient(60% 51.57% at 50% 50%, #0938DF 0%, rgba(9, 56, 223, 0) 100%);
    font-weight: bold;
}
.wrapperSlideMedal{
    width: 80%;
    max-width: 300px;
    margin-bottom: 15px;
    height: 70px;
    position: relative;
}
.wrapperSlideMedal div:not(.hexagono){
    height: 100%;
}
.hexagono{
    position: relative;
    display: flex;
    background-image: url('../../assets/img/team/hex.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    z-index: 999;
    transition: all .5s ease-out;
    justify-content: center;
    align-items: center;
}
.hexagono>img{
    width: 60%;
    object-fit: contain;
}
.wrapperSlideMedal :global(.slick-current) .hexagono{

    background-image: url('../../assets/img/team/hexSel.svg');
    transform: scale(1.5);
}
.one :global(.slick-track){
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapperModel{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
}
.wrapperModel>canvas{
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

/*arrows slider*/

.wrapperSlideMedal .icon_arrow{
    background: transparent url(../../assets/img/team/arrow.svg) no-repeat center !important;
    background-size: contain !important;
    top: 90%;
    z-index: 1;
    transition: all .5s ease-in-out;
    background-size: contain !important;
    width: 30px;
    height: 30px !important;
 }
 .wrapperSlideMedal :global(.slick-disabled){
     opacity: 0;
 }
 .wrapperSlideMedal .arrow_next:active{
     transform: scale(1.5) rotate(0deg);
 }
 .wrapperSlideMedal .arrow_prev:active{
    transform: scale(1.5) rotate(180deg);
 }
 .wrapperSlideMedal .icon_arrow::before{
     content: '';
 }
 .wrapperSlideMedal .arrow_next{
     top: 0;
     bottom: 0;
     margin: auto;
     transform: scale(1) rotate(0deg);
 }
 .wrapperSlideMedal .arrow_prev{
     top: 0;
     bottom: 0;
     margin: auto;
    transform: scale(1) rotate(180deg);
 }
