.wrapperProyectos{
    width: 100%;
    text-align: center;
    padding-bottom: 10%;
}

.wrapperTitle{
    margin-bottom: 60px;
}

/*arrows slider*/

.sliderCont .icon_arrow{
    background: transparent url(../../assets/img/arrow.svg) no-repeat center !important;
    background-size: contain !important;
    top: 90%;
    z-index: 1;
    transition: all .5s ease-in-out;
    background-size: contain !important;
    padding-top: 10%;
    height: 40px;
    width: 40px;
 }
 .sliderCont :global(.slick-disabled){
     opacity: 0;
 }
 .sliderCont .arrow_next:active{
     transform: scale(1.5) rotate(180deg);
 }
 .sliderCont .arrow_prev:active{
    transform: scale(1.5) rotate(0deg);
 }
 .sliderCont .icon_arrow::before{
     content: '';
 }
 .sliderCont .arrow_next{
     right: 0;
     top: 0;
     bottom: 0;
     margin: auto;
     transform: scale(1) rotate(180deg);
 }
 .sliderCont .arrow_prev{
     left: 0;
     top: 0;
     bottom: 0;
     margin: auto;
    transform: scale(1) rotate(0deg);
 }

.sliderCont{
    margin-top: 60px;
    width: 95%;
    height: 50vh;
    min-height: 470px;
    margin: 0 auto;
}

.slide{
    margin: 10px auto;
    width: 75%;
    padding-top: 120%;
    position: relative;
}
.slide>div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: -4px -4px 10px #14658f, 4px 4px 10px #082636;
}
.slideTextBox{
    width: 100%;
    height: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-end;
    position: absolute;
    padding-bottom: 7%;
    background: rgb(14,69,98);
    background: -moz-linear-gradient(180deg, rgba(14,69,98,0) 1%, rgba(14,69,96,0.5830707282913166) 33%, rgba(14,69,96,1) 71%);
    background: -webkit-linear-gradient(180deg, rgba(14,69,98,0) 1%, rgba(14,69,96,0.5830707282913166) 33%, rgba(14,69,96,1) 71%);
    background: linear-gradient(180deg, rgba(14,69,98,0) 1%, rgba(14,69,96,0.5830707282913166) 33%, rgba(14,69,96,1) 71%);

}

.slideTitle, .slideText{
    text-align: center;
    width: 100%;
    margin: 1% 0%;
}

.slideTitle>p{
    color: var(--primaryGreen);
}

.slideText>p{
    font-size: calc(.8rem + ((1vw - 19.2px) * 0.375));
    font-weight: 300;
}

.slideLine{
    width: 15%;
    margin-top: 1%;
    border-top: 2px solid;
    color: var(--primaryGreen);
    border-radius: 10px;
}

@media (max-width: 1024px){

    .wrapperTitle{
        margin-bottom: 20px;
    }

    .slide{
        padding-top: 85%;
    }
    

    .slideTitle>p{
        font-size: calc(1.6rem + ((1vw - 19.2px) * 0.375));
    }

    .slideText>p{
        font-size: calc(1.3rem + ((1vw - 19.2px) * 0.375));
        font-weight: 300;
    }

    .slideLine{
        width: 20%;
        
    }
    
}

@media only screen and (max-width: 832px) and (orientation:portrait){

    .wrapperTitle{
        padding-top: 40px;
    }
}