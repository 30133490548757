.wrapper{
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0% 2.77% 10% 0%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.wrapperTitle{
    width: 100%;
    position: relative;
    margin-top: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mediaCont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 2% 5%;
}
.mediaText{
    width: 30%;
    text-align: left;
    margin-right: 40px;
}
.mediaVideo{
    width: 65%;
    overflow: hidden;
    border-radius: 20px;
}
.wrapperSubTitle{
    width: 100%;
    margin: 3% 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wrapperSubTitle>h2{
    color: var(--primaryBlue);
}
.wrapperDescription{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wrapperDescription>p{
    white-space: break-spaces;
    line-height: 1.5em;
}
.wrappermedia{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4%;
}
.wrappermedia>img{
    width: 100%;
    object-fit: contain;
    margin-bottom: 10%;
}
.wrapperVideo{
    width: 100% !important;
    padding-top: 55%;
    position: relative;
}
.wrapperVideo>div{
    position: absolute;
    top: 0;
    left: 0;

}
.wrapperVideo video{
    width: 100%;
    height: 100%;
}
.line{
    min-height: 100px;
    border: 1px solid rgba(255, 255, 255, .2);   
    left: 50%;
    right: 50%;
    display: none;
    margin: 0px 10px;
}

@media only screen and (max-width: 1024px){
    .wrapperTitle{
        margin-bottom: 40px;
    }
    .wrapperTitle h1{
        font-size: calc(3.7rem + ((1vw - 19.2px) * 1.75));
    }
    .wrapperSubTitle{
        margin-bottom: 20px;
    }
    .wrapperSubTitle>h2{
        font-size: calc(2.2rem + ((1vw - 19.2px) * 0.875));
    }
    .mediaCont{
        padding: 2%;
    }
    .mediaText{
        margin-right: 40px;
    }
    .mediaVideo{
        width: 60%;
    }
    .wrapperVideo{
        padding-top: unset;
        height: 316px !important;
    }
}

@media only screen and (max-width: 832px) and (orientation:portrait){
    .wrapperTitle{
        margin-top: 25%;
    }
    .wrapperSubTitle>h2{
        font-size: calc(2rem + ((1vw - 19.2px) * 0.875));
        line-height: 1.5rem;
    }
    .mediaCont{
        flex-direction: column-reverse;
    }
    .mediaVideo{
        width: 100%;
    }
    .wrapperVideo{
        padding-top: unset;
        height: 260px !important;
    }
    .mediaText{
        width: 100%;
        margin: auto;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .wrapperSubTitle{
        width: 50%;
        margin: unset;
        text-align: right;
        justify-content: end;
    }
    .wrapperDescription{
        justify-content: start;
    }
    .wrapperDescription>p{
        white-space: unset;
    }
    .line{  
        display: block;
    }
}


