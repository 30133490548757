.logoWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo {
    width: 35%;
    margin-bottom: 3%;
}

.slogan{
    width: 80%;
}

.logo>img, .slogan>img{
    width: 100%;
}