.wrapper{
    width: 100%;
    height: 100vh;
    min-height: 700px;
    max-height: 850px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 10%;
}
.title{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.wrapperSlider{
    width: 95%;
    height: calc(90% - 20px);
    margin: auto;
}

.wrapperSlider div:not(:global(.slick-slide) div){
    height: 100%;    
}

.wrapperSlider :global(.slick-slide)>div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    width: 70%;
    margin: 0 15%;
}

.wrapperSlider>:global(.more)>div>div>div:global(.slick-current){
    width: 500px !important;
}

/*arrows slider*/

.wrapperSlider .icon_arrow{
    background: transparent url(../../assets/img/team/arrow.svg) no-repeat center !important;
    background-size: contain !important;
    top: 90%;
    z-index: 1;
    transition: all .5s ease-in-out;
    background-size: contain !important;
    width: 30px;
    height: 30px !important;
 }
 .wrapperSlider :global(.slick-disabled){
     opacity: 0;
 }
 .wrapperSlider .arrow_next:active{
     transform: scale(1.5) rotate(0deg);
 }
 .wrapperSlider .arrow_prev:active{
    transform: scale(1.5) rotate(180deg);
 }
 .wrapperSlider .icon_arrow::before{
     content: '';
 }
 .wrapperSlider .arrow_next{
     right: 0;
     top: 0;
     bottom: 0;
     margin: auto;
     transform: scale(1) rotate(0deg);
 }
 .wrapperSlider .arrow_prev{
     left: 0;
     top: 0;
     bottom: 0;
     margin: auto;
    transform: scale(1) rotate(180deg);
 }
:global(.slick-track){
    display: flex;
}
 @media only screen and (max-width: 832px) and (orientation:portrait){
    .title{
        padding-top: 40px;
    }
 }

 @media only screen and (max-width:600px) {
    .wrapperSlider :global(.slick-slide){
        width: 95vw !important;
        margin: 0 auto;
    }
     
 }