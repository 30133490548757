/* Aquí declaramos todos los valores de css globales de todo el proyecto */
@font-face {
  font-family: "QuickSand";
  src: url("./assets/fonts/Quicksand/Quicksand-Bold.ttf");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
  font-style: normal;
  font-display: swap;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  scroll-behavior: smooth;
  text-decoration: none;
}

:root{
  --primaryGreen: #7DC361;
  --primaryBlue: #55B4E6;
  --primaryRed: #F2696A;
  --blueMedium: #1B84BB;
  --greenMedium: #61AE42;
  --blueVariant: #104E6F;
  --blueDark: #0E4562;
  --greenDark: #4C8934;
}


/* Tamaño de la fuente min 12px a 320 - max 24 px a 1920 */
body{
  background-color: var(--blueDark);
  overflow-y: auto;
  overflow-x: hidden;
}

p{
  font-family: "Roboto";
  font-size: calc(1.125rem + ((1vw - 19.2px) * 0.375));
  line-height: 20px;
}
h1{ /*1920 -  64px  320 - 36px*/
  font-family: "QuickSand";
  font-size: calc(4rem + ((1vw - 19.2px) * 1.75));
}
h2{ /*1920 -  28px  320 - 14px*/
  font-family: "Roboto";
  font-size: calc(1.75rem + ((1vw - 19.2px) * 0.875));
  line-height: 32px;
  font-weight: normal;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:var(--blueMedium); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blueVariant); 
}
.backgroundVideo{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -2;
  display: flex;
  justify-content: center;
  align-items: center;  
  opacity: .3;
}
.backgroundVideo video{
  object-fit: cover;
}
.backgroundFlair{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backgroundFlair>img{
  transform: rotate(00deg);
  transform-origin: center;
  transform-style: preserve-3D;
  transition: transform .5s linear;
}

@media(max-width: 834px) and (orientation: portrait){

  p{
    font-size: calc(1.125rem + ((2.5vw - 19.2px) * 0.375));
  }

  h1{ /*1920 -  64px  320 - 36px*/
    font-size: calc(4rem + ((.1vw - 19.2px) * 1.75));
  }

  h2{ /*1920 -  28px  320 - 14px*/
    line-height: 1.75rem;

  }
}




@media (max-width: 320px) {
  h1 {
    font-size: 36px;
  }
  p {
    font-size: 12px;
  }
  h2{ 
    font-size: 14px;
  }
}


