.wrapper{
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.wrapperLottie{
    width: 50%;
}
.wrapperText{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding-right: 15%;
}
.wrapperText>img{
    max-width: 450px;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
    .wrapperLottie{
        width: 100%;
    }
    .wrapperText{
        width: 100%;
        padding: 0% 20%;
    }   
}