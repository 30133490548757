.teEntendemos {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column nowrap;
}

.titleHero {
    width: 50%;
    text-align: center;
}

.bulletsHero {
    width: 80%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}

.bullet {
    width: 30%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-start;
}

.iconBullet {
    width: 20%;
}

.iconBullet>img {
    width: 100%;
    height: 100%;
}

.textBullet {
    width: 70%;
}

.textBullet>p {
    width: 100%;
}

@media(max-width: 1024px){
    .bulletsHero {
        width: 80%;
        display: flex;
        flex-flow: column nowrap;
        height: 50%;
    }
    .bullet {
        width: 100%;
       
    }
    .iconBullet {
        width: 10%;
    }
}

@media(max-width: 832px) and (orientation: portrait){

    .teEntendemos {
        justify-content: center;
    }
    .titleHero {
        width: 80%;
        margin-bottom: 30px;
    }
}